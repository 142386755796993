import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"flex",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('div',{staticClass:"home px-5 pt-10"},[_c('h2',[_vm._v(_vm._s(_vm.$t('profile')))]),_c(VTextField,{attrs:{"lable":_vm.$t('name')},model:{value:(_vm.user.displayName),callback:function ($$v) {_vm.$set(_vm.user, "displayName", $$v)},expression:"user.displayName"}}),_c(VSelect,{attrs:{"items":_vm.languages,"item-text":"name","item-value":"value","return-object":"","menu-props":"auto","label":_vm.$t('language'),"hide-details":"","single-line":""},model:{value:(_vm.currentLang),callback:function ($$v) {_vm.currentLang=$$v},expression:"currentLang"}}),_c(VBtn,{staticClass:"mt-3",attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.updateProfile}},[_vm._v(" "+_vm._s(_vm.$t('update'))+" ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }