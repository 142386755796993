<template>
  <v-container
    fluid
    class="flex"
  >
    <v-row
      no-gutters
    >
      <v-col>
        <div class="home px-5 pt-10">
          <h2>{{ $t('profile') }}</h2>
          <v-text-field
            v-model="user.displayName"
            :lable="$t('name')"
          />
          <v-select
            v-model="currentLang"
            :items="languages"
            item-text="name"
            item-value="value"
            return-object
            menu-props="auto"
            :label="$t('language')"
            hide-details
            single-line
          />
          <v-btn
            color="success"
            class="mt-3"
            :loading="loading"
            @click="updateProfile"
          >
            {{ $t('update') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/remote-config'
export default Vue.extend({
  name: 'UserProfile',
  data: () => ({
    user: {
      displayName: ''
    },
    currentLang: localStorage.getItem('language'),
    languages: [],
    loading: false
  }),
  computed: {
    displayName: function () {
      return this.user.displayName
    }
  },
  created () {
    this.user.displayName = firebase.auth().currentUser.displayName
    const languages = firebase.remoteConfig().getValue('app_languages').asString()
    this.languages = JSON.parse(languages)
  },
  methods: {
    updateProfile () {
      this.loading = true
      firebase.auth().currentUser.updateProfile({
        displayName: this.user.displayName
      }).then(() => {
        this.$i18n.locale = this.currentLang.value
        localStorage.setItem('language', this.currentLang.value)
        this.$store.dispatch('user/patch', { language: this.currentLang.value, name: this.user.displayName })
        document.title = this.$t('app-title')
        this.loading = false
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    }
  }
})
</script>
